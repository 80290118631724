import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const ReplyBasedOrUrlBased = ({ location }) => {
  const title = "即时赢是否支持回复或URL类型？"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="回复和URL类型都支持。 建议采用直接信息通知开奖结果的方法。 该信息包含一个URL，用户可以通过导航到该URL来检查结果。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              PARKLoT组织的活动是由PARKLoT回复还是基于URL？
            </h1>
            <p className="qa-page__answer">URL类型。</p>
            <p className="qa-page__text">
              抽奖结果会通过直接信息通知。
              该信息包含一个URL，你可以在浏览器中查看该URL以检查结果。
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">URL和回复类型之间的差异</h2>

                <p className="qa-page__text">
                  抽奖结果有几种通知方法。 各自的特点描述如下。
                </p>

                <h3 className="qa-page__h3">什么是URL类型？</h3>
                <p className="qa-page__text">
                  这是一种参与方式，活动的抽奖结果通过一个URL通知。
                  <br />
                  参与者点击该网址，并在他们被重新定向到的网站上查看抽奖结果。
                </p>

                <h3 className="qa-page__h3">什么是回复类型？</h3>
                <p className="qa-page__text">
                  活动抽奖结果通过回信通知。
                  <br />
                  没有参加活动的用户也可以查看活动的抽奖结果。
                </p>
              </div>

              <div>
                <h2 className="qa-page__h2">
                  为什么在PARKLoT中使用URL类型（通过DM发送URL）？
                </h2>

                <p className="qa-page__text">
                  使用这种方法是为了
                  "确保你可以在不需要登录的情况下收到你的奖品"。
                  <br />
                  *请注意，有些服务需要用同一方法登录。
                  <br />
                  *如果达到发送直接信息的限制，你将不会收到信息通知，在这种情况下，你必须登录查看结果。
                </p>

                <p className="qa-page__text">
                  在回复类型的情况下，"检查抽奖结果需要登录"。
                  <br />
                  由于Twitter的规范，回复可以被所有参与者看到，所以必须用你申请的Twitter账户登录，以验证你的账户。
                </p>

                <p className="qa-page__text">
                  可以将结果通知方式自定义为回复类型，但接收礼物的URL会被获胜者以外的用户知道。
                  因此，在检查结果和接收礼物时，都需要有Twitter登录来进行账户验证。
                </p>

                <p className="qa-page__text">
                  一些账户已经证实，在回复的情况下，用户不会得到通知，所以这基本上是被废弃的。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default ReplyBasedOrUrlBased
